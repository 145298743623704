<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        When
        <number-value :value="molReacts" unit="\text{mol}" />
        of fictional compounds
        <chemical-latex content="A" />
        and
        <chemical-latex content="B" />
        are put in a
        <stemble-latex content="$1.00\,\text{L}$" />
        container at a constant temperature, the following reaction occurs:
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="A(g) + 3 B(g) <=> 2 C(g) + 2 D(g)" />
      </p>

      <p class="mb-2">
        where C(g) and D(g) are also fictional. What is the equilibrium constant
        <stemble-latex content="$\text{K}_\text{c}$" />, given that there is
        <number-value :value="molC" unit="\text{mol}" />
        of compound C in the container when the system reaches equilibrium?
      </p>

      <calculation-input
        v-model="inputs.Keq"
        class="mb-0"
        prepend-text="$\text{K}_\text{c}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalReactionInput from '@/tasks/components/inputs/ChemicalReactionInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'Question460',
  components: {
    ChemicalNotationInput,
    LatexNumber,
    NumberValue,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        Keq: null,
      },
    };
  },
  computed: {
    molReacts() {
      return this.taskState.getValueBySymbol('molReacts').content;
    },
    molC() {
      return this.taskState.getValueBySymbol('molC').content;
    },
  },
};
</script>
